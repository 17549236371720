
<ion-content >
    <!-- <ion-segment [(ngModel)]="calendar.mode">
        <ion-segment-button value="month">
            <ion-label>Month</ion-label>
        </ion-segment-button>
        <ion-segment-button value="week">
            <ion-label>Week</ion-label>
        </ion-segment-button>
        <ion-segment-button value="day">
            <ion-label>Day</ion-label>
        </ion-segment-button>
    </ion-segment> -->


    <ion-row>
        <!-- Move back one screen of the slides -->
        <ion-col size="2">
            <ion-button fill="clear" (click)="back()">
            <ion-icon name="arrow-back" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-col>
        
        <ion-col size="8" class="ion-text-center">
            <h2>{{ viewTitle }}</h2>
        </ion-col>
        
        <!-- Move forward one screen of the slides -->
        <ion-col size="2">
            <ion-button fill="clear" (click)="next()">
            <ion-icon name="arrow-forward" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>

    <calendar style="padding: 20px;"
    [eventSource]="eventSource"
    [calendarMode]="calendar.mode"
    [currentDate]="calendar.currentDate"
    [startingDayWeek]="calendar.startingDayWeek"

    (onEventSelected)="onEventSelected($event)"
    (onTitleChanged)="onViewTitleChanged($event)"
    startHour="5"
    endHour="24"
    step="30"
    startingDayWeek="1">

    </calendar>
</ion-content>



 