import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument  } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {User, WorkPeriod, WorkDay, Time} from 'src/interfaces';
import {FormGroup, FormControl} from '@angular/forms';

interface Position {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {

  weekday = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
  positions = ["Employés de champ-Aux Fruits des Champs", "Employés de champ-Potager Eardley", "Employés du kiosque-Potager Eardley", "Autres employés-Potager Eardley", "Administrateur", 'Inactif'];
  positionRef = ["ChampsAFC", "ChampsPe", "Kiosque", "Autres", "Administration", "Inactif"];

  selectedPosition: string;
  prettyPosition: string;

  workDayCollection: AngularFirestoreCollection<WorkDay>;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  private userCollection: AngularFirestoreCollection<User>;
  userList$: Observable<User[]>;
  userList: User[] = [];
  filteredUserList: User[] = [];
  maxDate: Date;
//To add more position, add checkbox in ui, add lists here as well as bool
  champsAFCFilterList: User[] = [];
  champsPEFilterList: User[] = [];
  kioskFilterList: User[] = [];
  autresFilterList:User[] = [];
  adminFilterList: User[] = [];
  inactifFilterList: User[] = [];

  champsAFCFilter: boolean = false;
  champsPEFilter: boolean = false;
  autresFilter: boolean = false;
  kioskFilter: boolean = false;
  adminFilter: boolean = false;
  inactifFilter: boolean = false;

  newUser: boolean = false;
  updateUser: boolean = false;
  admin: boolean = false;

  fname: string;
  lname: string;
  position: string;
  pin: string;

  selectedUser: number;
  newPosition:string;

  errorFlag = false;
  errorMessage = '';
  
  constructor(
    private firestore: AngularFirestore,
  ) {
  }

  sales;
  data;

  ngOnInit() {
    this.setUsers();
    this.calculateDates();
  }
  createUser(){
    console.log("create user")

    const info = {

      id: this.fname.trim() + "." + this.lname.trim(),
      isAdmin: this.admin,
      name: this.fname.trim() + " " + this.lname.trim(),
      pin: this.pin.replace(/\s/g, ""),
      position: this.position
    }

    this.firestore.collection('Users/').doc(info.id).set(info);
    this.firestore.collection('Users/').doc(info.id).collection('Days').doc("initialize").set(info)

  }
  updateUserDoc(){
    console.log("Update User");
    console.log(this.selectedUser);
    console.log(this.userList[this.selectedUser])
    console.log(this.newPosition);

    var newU: any = {
      ... this.userList[this.selectedUser],
      position: this.newPosition
    }
    console.log(newU);

    let eventDoc = this.firestore.doc<User>('Users/'+newU.id);
    eventDoc.set(newU);
  }

  calculateDates(){
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getUTCMonth();
    const currentDay = new Date().getDate();
    this.maxDate = new Date(currentYear, currentMonth, currentDay);
  }

  getFilteredUsers() {
    //to add positions, add more entries to this nice if statement
    //positions = ["Employés de champ-Aux Fruits des Champs", "Employés de champ-Potager Eardley", "Employés du kiosque-Potager Eardley", "Autres employés-Potager Eardley", "Administrateur"];
    //positionRef = ["ChampsAFC", "ChampsPe", "Kiosque", "Autres", "Inactif", "Administration"];
    if(!this.userList$){
      return [];
    } else {
      if(this.kioskFilter && this.champsAFCFilter){
        return [];
      } else if(this.kioskFilter){
        this.prettyPosition = this.positions[2];
        this.selectedPosition = this.positionRef[2];
        return this.kioskFilterList;
        
      } else if(this.champsAFCFilter){
        this.prettyPosition = this.positions[0];
        this.selectedPosition = this.positionRef[0];
        return this.champsAFCFilterList;
      }else if(this.adminFilter){
        this.prettyPosition = this.positions[4];
        this.selectedPosition = this.positionRef[4];
        return this.adminFilterList;
      }else if(this.champsPEFilter){
        this.prettyPosition = this.positions[1];
        this.selectedPosition = this.positionRef[1];
        return this.champsPEFilterList;
      }else if(this.autresFilter){
        this.prettyPosition = this.positions[3];
        this.selectedPosition = this.positionRef[3];
        return this.autresFilterList;
      }else if(this.inactifFilter){
        this.prettyPosition = this.positions[5];
        this.selectedPosition = this.positionRef[5];
        return this.inactifFilterList;
      } else {
        this.selectedPosition = "Total-Employee";
        this.prettyPosition = "Tout les employees";
        return this.userList;
        
      }
    }
  }

  setUsers(){
    this.userCollection = this.firestore.collection<User>('Users');
    this.userList$ = this.userCollection.valueChanges();
    this.userList$.forEach(u => {
      this.userList = u;
      this.filteredUserList = u;
      //to add positions, copy paste this line with the new position name
      this.kioskFilterList = this.userList.filter(u => u.position == 'kiosque');
      this.champsAFCFilterList = this.userList.filter(u => u.position == 'champs-afc');
      this.champsPEFilterList = this.userList.filter(u => u.position == 'champs-pe');
      this.autresFilterList = this.userList.filter(u => u.position == 'autres');
      this.adminFilterList = this.userList.filter(u => u.position == 'admin');
      this.inactifFilterList = this.userList.filter(u => u.position == 'inactif');
    });
  }

  timeDiff(start: Time, end: Time) : Time{
    const minDiff = (end.hour*60 + end.minute) - (start.hour*60 + start.minute);
    return {hour: Math.floor(minDiff/60), minute: (minDiff%60)};
  }

  addTime(sum: Time, t: Time) {
    var min = (sum.hour*60 + sum.minute) + (t.hour*60 + t.minute);
    return {hour: Math.floor(min/60), minute: (min%60)};
  }

  exportExcel(type: string)  {
    let globalData = [];
    let temp = {};
    let start = this.range.value.start.getFullYear()+"."+("0" + (this.range.value.start.getMonth() + 1)).slice(-2)+"."+("0" + this.range.value.start.getDate()).slice(-2);
    let end = this.range.value.end.getFullYear()+"."+("0" + (this.range.value.end.getMonth() + 1)).slice(-2)+"."+("0" + this.range.value.end.getDate()).slice(-2);
    
    const diffInMs = this.range.value.end - this.range.value.start;
    const diffInDays = 2 + (diffInMs / (1000 * 60 * 60 * 24));

    for(let u of this.getFilteredUsers()){
      let ref = 'Users/'+u.id+'/Days/';
      
      let userData = [];

      this.workDayCollection = this.firestore.collection<WorkDay>(ref, r => r.where('date', ">=", start).orderBy('date', 'asc').limit(diffInDays+2));
      this.workDayCollection.valueChanges().forEach(workDays => {
        
        for(let wd of workDays){
          console.log(workDays)
          if(wd.date >= start && wd.date <= end){
            let workDay = this.setWorkDay(wd)
            let dateObj = new Date(Number(wd.date.split(".")[0]),Number(wd.date.split(".")[1])-1,Number(wd.date.split(".")[2]));
            temp = {};
            temp = {Name: u.name, Date: workDay.date, Day: this.weekday[dateObj.getDay()], Notes: workDay.notes};
            console.log(temp)
            let wps = workDay.workPeriods;
            let hourSum = {hour:0, minute: 0};
            for(const [index, wp] of wps.entries()){
              let startName = "Start "+(index+1);
              let endName = "End "+(index+1);
              let hours = "Hours "+(index+1);
              let descriptionName = "Description "+(index+1);
              let wpTotal = this.timeDiff(wp.start, wp.end);
              hourSum = this.addTime(hourSum, wpTotal);
              temp =  { ... temp, 
                [startName]: wp.start.hour+":"+wp.start.minute, 
                [endName]: wp.end.hour+":"+wp.end.minute, 
                [hours]: wpTotal.hour+":"+wpTotal.minute, 
                [descriptionName]: wp.description};
            }
            for(let i=wps.length+1; i<=4; i++){
              let startName = "Start "+(i);
              let endName = "End "+(i);
              let hours = "Hours "+(i);
              let descriptionName = "Description "+(i);
              temp =  { ... temp, 
                [startName]: "", 
                [endName]: "",
                [hours]: "", 
                [descriptionName]: ""
              };
            }
            temp = { ... temp,
              TOTAL: hourSum.hour+":"+hourSum.minute
            }
            
            userData.push(temp);
            console.log(userData);
          }
        }
        globalData.push(userData.sort(function(a, b) {
          var keyA = a.Date,
            keyB = b.Date;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }));
        console.log(globalData)
        
      });
    }
    switch (type) {
      case "short":
        this.createShortExcel(globalData, this.getFilteredUsers());
        break;

      case "admin":
        console.log(globalData)
        this.createAdminExcel(globalData, this.getFilteredUsers());
        break;
      default:
        this.createExcel(globalData, this.getFilteredUsers());
        break;
    }
    
  }

  createAdminExcel(globalData, users){
    console.log("Admin clicked");
    let worksheets = {};
    let SheetNames = [];
    let blank = [];
    
    setTimeout(()=>{
      import("xlsx").then(xlsx => {
        let workbook : any = { Sheets: {}, SheetsNames: []};
        // Iterates through users
        let sheet = xlsx.utils.aoa_to_sheet(blank)
        xlsx.utils.sheet_add_aoa(sheet, [[this.prettyPosition]]);

        for(const [index, d] of globalData.entries()){
          var total = 0;
          for(let s of globalData[index]){
            

            total += (Number(s.TOTAL.split(":")[0])*60);
            total += Number(s.TOTAL.split(":")[1]);
          }
          
          xlsx.utils.sheet_add_aoa(sheet, [[""]], {origin:-1});

          xlsx.utils.sheet_add_aoa(sheet, [["Nom",users[index].name]], {origin:-1});
          xlsx.utils.sheet_add_json(sheet, d, {origin:-1});
          xlsx.utils.sheet_add_aoa(sheet, [["Somme", total/60]], {origin:-1});


        }
        worksheets = { ... worksheets, [this.selectedPosition]: sheet};
        SheetNames = [... SheetNames, [this.selectedPosition]];

        workbook = { Sheets: worksheets, SheetNames: SheetNames }
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Rapport ");
      });
    },1000);

  }
  

  createExcel(globalData, users){
    let worksheets = {};
    let SheetNames = [];
    setTimeout(()=>{
      import("xlsx").then(xlsx => {
        let workbook : any = { Sheets: {}, SheetsNames: []};
        // Iterates through users
        for(const [index, d] of globalData.entries()){
          let total : Time = {hour: 0, minute: 0};
          for(let d of globalData[index]){
            total = this.addTime(total, {hour: Number(d.TOTAL.split(":")[0]), minute: Number(d.TOTAL.split(":")[1])});
          }
          let name = users[index].name.substring(0,31);
          let sheet = xlsx.utils.json_to_sheet(d);
          xlsx.utils.sheet_add_aoa(sheet, [["Nom",users[index].name]], {origin:-1});
          xlsx.utils.sheet_add_aoa(sheet, [["Somme",total.hour+"."+total.minute]], {origin:-1});

          worksheets = { ... worksheets, [name]: sheet};
          SheetNames = [... SheetNames, [name]];
        }
        workbook = { Sheets: worksheets, SheetNames: SheetNames }
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Rapport Long");
      });
    },1000);
  }
  exportShortExcel(type: string)  {
    let globalData = [];
    let temp = {};
    let start = this.range.value.start.getFullYear()+"."+("0" + (this.range.value.start.getMonth() + 1)).slice(-2)+"."+("0" + this.range.value.start.getDate()).slice(-2);
    let end = this.range.value.end.getFullYear()+"."+("0" + (this.range.value.end.getMonth() + 1)).slice(-2)+"."+("0" + this.range.value.end.getDate()).slice(-2);
    
    var getDaysArray = function(start: Date, end: Date) {
      for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
          arr.push(new Date(dt));
      }
      return arr;
    };
    
    const diffInMs = this.range.value.end - this.range.value.start;
    const diffInDays = 2 + (diffInMs / (1000 * 60 * 60 * 24));
    
    for(let u of this.getFilteredUsers()){
      if (this.errorFlag){
        return
      }
      let ref = 'Users/'+u.id+'/Days/';
      let userData = [];
      let userDates = [];

      this.workDayCollection = this.firestore.collection<WorkDay>(ref, r => r.where('date', ">=", start).orderBy('date', 'asc').limit(diffInDays+2));
      this.workDayCollection.valueChanges().forEach(workDays => {
        if(this.errorFlag){
          return
        }
        for(let wd of workDays){
          if(wd.date >= start && wd.date <= end){
            // console.log(wd);
            // console.log(u);
            let workDay = this.setWorkDay(wd)
            let dateObj = new Date(Number(wd.date.split(".")[0]),Number(wd.date.split(".")[1])-1,Number(wd.date.split(".")[2]));
            userDates.push(dateObj);
            temp = {};
            temp = {Date: workDay.date, Jour: this.weekday[dateObj.getDay()]};
            let wps = workDay.workPeriods;
            let hourSum = {hour:0, minute: 0};
            for(const [index, wp] of wps.entries()){

              if (wp.start == null || wp.end == null ){
                this.errorFlag = true;
                this.errorMessage = "Error with: " + JSON.stringify(wd) + JSON.stringify(u)
                console.log("This should flag an error")
                globalData = []
                return false;
              }

              let startName = "Debut "+(index+1);
              let endName = "Fin "+(index+1);
              let hours = "Heures "+(index+1);
              
              let wpTotal = this.timeDiff(wp.start, wp.end);
              hourSum = this.addTime(hourSum, wpTotal);
              temp =  { ... temp, 
                [startName]: wp.start.hour+":"+wp.start.minute, 
                [endName]: wp.end.hour+":"+wp.end.minute, 
                [hours]: wpTotal.hour+":"+wpTotal.minute};
            }
            for(let i=wps.length+1; i<=4; i++){
              let startName = "Debut "+(i);
              let endName = "Fin "+(i);
              let hours = "Heures "+(i);
              
              temp =  { ... temp, 
                [startName]: "", 
                [endName]: "",
                [hours]: "", 
                
              };
            }
            temp = { ... temp,
              TOTAL: hourSum.hour+":"+hourSum.minute
            }
            userData.push(temp);
          }
        }

        var dates = getDaysArray(this.range.value.start, this.range.value.end)
        
        for (let index = 0; index < userDates.length; index++) {
          for (let i = 0; i < dates.length; i++) {
            if (userDates[index].getTime() == dates[i].getTime()) {
              dates.splice(i,1);
            } 
          }
        }


        for (let date of dates){
          temp = {};
          temp = {Date: date.getFullYear()+"."+("0" + (date.getMonth() + 1)).slice(-2)+"."+("0" + date.getDate()).slice(-2), Jour: this.weekday[date.getDay()]};

          for(let i=1; i<=4; i++){
            let startName = "Debut "+(i);
            let endName = "Fin "+(i);
            let hours = "Heures "+(i);
            
            temp =  { ... temp, 
              [startName]: "", 
              [endName]: "",
              [hours]: "", 
              
            };
          }
          temp = { ... temp,
            TOTAL: "0:0"
          }
          userData.push(temp);

        }

        globalData.push(userData.sort(function(a, b) {
          var keyA = a.Date,
            keyB = b.Date;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }));
      });
    }
    if (this.errorFlag == true){
      return
    }
    else{
      switch (type) {
        case "short":
          console.log(this.errorFlag)
          this.createShortExcel(globalData, this.getFilteredUsers());
          break;
        
        default:
          this.createExcel(globalData, this.getFilteredUsers());
          break;
      }
    }
    
    
  }

  createShortExcel(globalData, users){
    if(this.errorFlag){
      return
    }
    console.log("Short clicked");
    let worksheets = {};
    let SheetNames = [];
    let blank = [];
    
    setTimeout(()=>{
      import("xlsx").then(xlsx => {
        let workbook : any = { Sheets: {}, SheetsNames: []};
        // Iterates through users
        let sheet = xlsx.utils.aoa_to_sheet(blank)
        xlsx.utils.sheet_add_aoa(sheet, [[this.prettyPosition]]);

        for(const [index, d] of globalData.entries()){
          var total = 0;
          for(let s of globalData[index]){
            

            total += (Number(s.TOTAL.split(":")[0])*60);
            total += Number(s.TOTAL.split(":")[1]);
          }
          
          xlsx.utils.sheet_add_aoa(sheet, [[""]], {origin:-1});

          xlsx.utils.sheet_add_aoa(sheet, [["Nom",users[index].name]], {origin:-1});
          xlsx.utils.sheet_add_json(sheet, d, {origin:-1});
          xlsx.utils.sheet_add_aoa(sheet, [["Somme", total/60]], {origin:-1});


        }
        worksheets = { ... worksheets, [this.selectedPosition]: sheet};
        SheetNames = [... SheetNames, [this.selectedPosition]];

        workbook = { Sheets: worksheets, SheetNames: SheetNames }
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Rapport ");
      });
    },1000);

  }

  // Saves File
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(
        data,
        fileName + this.prettyPosition +" " + (this.range.value.start.getFullYear()+"-"+("0" + (this.range.value.start.getMonth() + 1)).slice(-2)+"-"+("0" + this.range.value.start.getDate()).slice(-2)) + " au " + (this.range.value.end.getFullYear()+"-"+("0" + (this.range.value.end.getMonth() + 1)).slice(-2)+"-"+("0" + this.range.value.end.getDate()).slice(-2)) + EXCEL_EXTENSION
        //Rapport Aux Fruits des Champs Employes Champs 2022-05-10 > 2022-05-10.xlsx
      );
    });
  }

  rangeSelected(){
    return this.range.value.start && this.range.value.end
  }

  setWorkDay(wd): WorkDay{
    let temp : WorkPeriod[] = [];
    for(let wp of JSON.parse(wd.workPeriod)){
      temp.push(this.newWorkPeriod(wp.description, wp.start, wp.end));
    }
    return {
      "isWorking": wd.isWorking,
      "date": wd.date,
      "notes": wd.notes,
      "workPeriods": temp
    }
  }

  newWorkPeriod(description: string, start: Time, end: Time) : WorkPeriod{
    return {
      description,
      start,
      end
    }
  }
}
