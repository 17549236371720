<ion-toolbar>
    <ion-title mode="md"> EardleyCo - {{selectedName}}</ion-title>
    <ion-button color="dark" size="small" fill="outline" slot="end" *ngIf="verified" (click)="showCalendarPage()">
        <ion-icon name="calendar-outline"></ion-icon>
    </ion-button>
    <ion-button color="dark" size="small" fill="outline" slot="end" *ngIf="verified" (click)="resetVariables()">
        <ion-icon name="arrow-undo-outline"></ion-icon>
    </ion-button>
    <ion-button color="dark" size="small" fill="outline" slot="end" *ngIf="showAdminIcon()" (click)="showAdminPage()">
        <ion-icon name="person-outline"></ion-icon>
    </ion-button>
</ion-toolbar>

<div *ngIf="!verified">
    <mat-list role="login" class="login">
        <mat-list-item role="listitem" style="padding: 15px;">
            <mat-form-field appearance="fill">
                <!-- TODO: Language? -->
                <mat-label>Nom/Nombre</mat-label>
                <mat-select [(ngModel)]="selectedName" name="selectedName">
                    <mat-option value="{{n.name}}" *ngFor="let n of userList; index as i">
                        {{n.name}}
                        </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-list-item>
        <div style="padding: 20px;"></div>
        <mat-list-item role="listitem"  style="padding: 15px;">
            <mat-form-field class="example-form-field" appearance="fill">
                <!-- TODO: Language? -->
                <mat-label>Pin</mat-label>
                <input matInput type="password" [(ngModel)]="pin">
                </mat-form-field>
        </mat-list-item>

        <div style="padding: 20px;"></div>

        <mat-label style="padding: 25px;" class="errorMessage" id="error"></mat-label>
        
        <mat-list-item role="listitem" class="login" style="padding: 15px;">
                <!-- TODO: Language? -->
            <button mat-raised-button (click)="login()">Login</button>
        </mat-list-item>
    </mat-list>
    
</div>

<ion-content *ngIf="verified && !showAdmin && !showCalendar">
    <ion-grid>

        <ion-row>
            <ion-item>
                <ion-card class="demo-inline-calendar-card">
                    <mat-calendar 
                    [(selected)]="selectedDate" 
                    (selectedChange)="selectDate('end', $event)" 
                    [minDate]= "minDate" 
                    [maxDate]="maxDate" >
                    </mat-calendar>
                </ion-card>
            </ion-item>
        </ion-row>
        <ion-row style="padding: 10px;">
            <mat-form-field class="example-form-field" appearance="fill">
                <!-- TODO: Language? -->
                <mat-label>Notes/Notas</mat-label>
                <input matInput type="text" [(ngModel)]="selectedWorkDay.notes" disabled="{{!dateSelected()}}">
            </mat-form-field>
        </ion-row>

        <ion-row>
            <mat-checkbox [(ngModel)]="didWork" labelPosition="before">
                As-tu travaillé aujourd'hui?/¿Trabaje ese dia?
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="edit" labelPosition="before">
                Modification des données?
                /¿Modificar los datos?
            </mat-checkbox>
        </ion-row>

        <ion-row id="dataEntry" *ngIf="didWork" >
            <ion-list>
                <ion-item style="padding: 10px;" >
                    <p [ngClass]="{'text': didSave}" >Période de travail/Tiempo de trabajo </p>
                </ion-item>

                <ion-item *ngFor="let wp of selectedWorkDay.workPeriods; index as i">
                    <ion-grid>
                        <ion-col>
                            <ion-row style="padding: 10px;">
                                <b >
                                    Période {{i+1}} {{days[selectedDate.getDay()] + " " + selectedDate.getDate() + " " + months[selectedDate.getMonth()] + " " + selectedDate.getFullYear()}}
                                </b>
                            </ion-row>
                            <ion-row>
                                <ion-card class="card-center">
                                    <ion-row>
                                        <ion-col>
                                            <b [ngClass]="{'text': didSave}">
                                                Temps d'entrée/Hora de inicio
                                            </b>
                                            <ngb-timepicker 
                                            (click)="didSave = true" 
                                            [(ngModel)]="this.selectedWorkDay.workPeriods[i].start" 
                                            [hourStep]="hourStep" 
                                            [minuteStep]="minuteStep" 
                                            [disabled]="!edit"
                                            
                                            >
                                            </ngb-timepicker>


                                        </ion-col>
                                        <ion-col>
                                            <b [ngClass]="{'text': didSave}">
                                                Temps de sortie/Hora de salida
                                            </b>
                                            <ngb-timepicker 
                                            (click)="didSave = true"
                                             [(ngModel)]="this.selectedWorkDay.workPeriods[i].end" 
                                             [hourStep]="hourStep" 
                                             [minuteStep]="minuteStep" 
                                             [disabled]="!edit"
                                             ></ngb-timepicker>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row *ngIf="isKiosk()">
                                        <mat-form-field class="example-full-width" appearance="fill" style="width: 350px;">
                                            <mat-label [ngClass]="{'text': didSave}">Travail fait/Trabajo realizado</mat-label>
                                            <input (click)="didSave = true" type="text"
                                                placeholder="Pick one"
                                                matInput
                                                [(ngModel)]="selectedWorkDay.workPeriods[i].description"
                                                [formControl]="myControl[i]"
                                                [matAutocomplete]="auto"
                                                [disabled]="!edit">
                                            <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                {{option}}
                                            </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </ion-row>
                                </ion-card>
                            </ion-row>
                        </ion-col>
                    </ion-grid>
                </ion-item>

            </ion-list>
        </ion-row>
        <ion-item *ngIf="didSave" class="error-div">
            <ion-text class="errorMessage" id="errorHome">
                ASSUREZ-VOUS DE SAUVEGARDER VOS CHANGEMENT/ASEGÚRESE DE GUARDAR SUS CAMBIOS
            </ion-text>
        </ion-item>
        <ion-item>
            <ion-row>
                <ion-col>
                    <button mat-raised-button (click)="saveWorkDay()" disabled="{{disableSave()}}">
                        <mat-icon>save</mat-icon>
                    </button>
                </ion-col>
                <ion-col>
                    <button  mat-raised-button (click)="addWorkPeriod()" disabled="{{!dateSelected()}}">
                        <mat-icon>add</mat-icon>
                    </button>
                </ion-col>
                <ion-col>
                    <button  mat-raised-button (click)="removeWorkPeriod()" disabled="{{!dateSelected()}}">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ion-col>
            </ion-row>
        </ion-item>
        <ion-row style="height: 100px">

        </ion-row>

    </ion-grid>
</ion-content>
    
    <div *ngIf="verified && showAdmin">
        <app-admin></app-admin>
    </div>
    
<!-- <ion-card *ngIf="verified && showCalendar"> -->
    <app-calendar *ngIf="verified && showCalendar" [selectedUser]="selectedUser" ></app-calendar>
<!-- </ion-card> -->


