import { Component, Input, OnInit, ChangeDetectionStrategy, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument  } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {MatDatepickerModule,} from '@angular/material/datepicker';
import { ChangeDetectorRef } from '@angular/core';
import {User, WorkPeriod, WorkDay, Time} from 'src/interfaces';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HomeComponent implements OnInit {

  showAdmin: boolean = false;
  showCalendar: boolean = false;
  selectedName: string;
  selectedUser: User;
  pin: string;
  verified: boolean = false;
  didSave:boolean = true;
  

  minDate: Date;
  maxDate: Date;
  currentDate: string ;
  selectedDate: Date;
  selectedWorkDay: WorkDay;
  didWork: boolean = false;
  edit: boolean = false;
  error: string;

  private userCollection: AngularFirestoreCollection<User>;
  userList$: Observable<User[]>;
  userList: User[] = [];

  startTime = [{hour: 5, minute: 0},{hour: 10, minute: 30},{hour: 17, minute: 0},{hour: 0, minute: 0}];
  stopTime = [{hour: 10, minute: 0},{hour: 16, minute: 30},{hour: 21, minute: 0},{hour: 0, minute: 0}];
  days = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
  months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Decembre'];

  hourStep = 1;
  minuteStep = 5;
  
  myControl = [new FormControl(), new FormControl(), new FormControl(), new FormControl()];
  filteredOptions: Observable<string[]>;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  
  constructor(
    private firestore: AngularFirestore,
    private ref: ChangeDetectorRef
  ) {
    setInterval(() => {
      this.ref.detectChanges()
    }, 250);
  }

  ngOnInit(): void {
    
    this.selectedWorkDay = this.newWorkDay(false, "", "", [])
    this.getuserList();
    this.calculateDates();
    this.currentDate = "Please select a date on the calendar.";
    this.error = "Error";
    console.log("V1.0.0-2024-06-27");

    for (let index = 0; index < this.myControl.length; index++) {
      this.filteredOptions = this.myControl[index].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
      
    }

    //this.dev_login();

  }
  resetVariables(){
    this.verified = false;
    this.showAdmin = false;
    this.showCalendar = false;
    this.didWork = false;
    this.selectedWorkDay.date = ''
    this.selectedWorkDay.isWorking = false;
    this.selectedWorkDay.notes = '';
    this.selectedWorkDay.workPeriods = [];
    this.selectedDate = null;
  }
  

  calculateDates(){
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getUTCMonth();
    const currentDay = new Date().getDate();
    //Change this line to control access to how many days the user can change in their database
    this.minDate = new Date(currentYear, currentMonth, currentDay-51);
    this.maxDate = new Date(currentYear, currentMonth, currentDay);
  }

  private workDayDocument: AngularFirestoreDocument<any>;
  workDay$: Observable<any>;

  selectDate(type: any, event:any){
    this.edit = false;

    if (this.didWork){
      this.didSave = true;
      
    }
    
    this.currentDate = this.days[this.selectedDate.getDay()] + " " + this.months[this.selectedDate.getMonth()] + " " + this.selectedDate.getDate() + ", " + this.selectedDate.getFullYear();
    if(this.selectedDate){
      const currentYear = this.selectedDate.getFullYear();
      const currentMonth = ("0" + (this.selectedDate.getMonth() + 1)).slice(-2);
      const currentDay = ("0" + this.selectedDate.getDate()).slice(-2);
      this.workDayDocument = this.firestore.doc<any>('Users/'+this.selectedUser.id+'/Days/'+currentYear+'.'+currentMonth+'.'+currentDay);
      this.workDay$ = this.workDayDocument.valueChanges();
      this.workDay$.forEach(u => {
        if(!u){
          this.workDayDocument.set({"notes": "", "workPeriod": JSON.stringify([])});
          this.edit = true;
        } else {
          
          this.selectedWorkDay = this.setWorkDay(u);
          if(this.selectedWorkDay.isWorking){
            this.didWork = this.selectedWorkDay.isWorking;
            this.edit = false;
          } else {
            this.didWork = false;
          }
        }
      })
    }
  }

  setWorkDay(wd): WorkDay{
    let temp : WorkPeriod[] = [];
    console.log(wd);
    for(let wp of JSON.parse(wd.workPeriod)){
      temp.push(this.newWorkPeriod(wp.description, wp.start, wp.end));
    }
    return {
      "isWorking": wd.isWorking,
      "date": wd.date,
      "notes": wd.notes,
      "workPeriods": temp,
    }
  }


  saveWorkDay(){
    this.didSave = false;
    const currentYear = this.selectedDate.getFullYear();
    const currentMonth = ("0" + (this.selectedDate.getMonth() + 1)).slice(-2);
    const currentDay = ("0" + this.selectedDate.getDate()).slice(-2);
    if(this.selectedDate){
      this.workDayDocument.update({"date": currentYear+'.'+currentMonth+'.'+currentDay, "isWorking": this.didWork, "notes": this.selectedWorkDay.notes, "workPeriod": JSON.stringify(this.selectedWorkDay.workPeriods)});
    }
    this.edit = false;
  }

  addWorkPeriod(){
    this.didSave = true;
    if(this.selectedDate){
      if(this.selectedWorkDay.workPeriods.length < 4){
        let st;
        let et;
        if(this.selectedWorkDay.workPeriods.length == 0){
          st = this.startTime[0];
          et = this.stopTime[0];
        } else {
          let len = this.selectedWorkDay.workPeriods.length-1;

          st = this.startTime[this.selectedWorkDay.workPeriods.length-1];
          et = this.stopTime[this.selectedWorkDay.workPeriods.length-1];
          st = { ... this.selectedWorkDay.workPeriods[len].end,
            hour: this.selectedWorkDay.workPeriods[len].end.hour + 1
          }
          et = { ... st,
            hour: st.hour + 1

          }
          
          
        }
        console.log(st);
        console.log(et);
        this.selectedWorkDay.workPeriods.push(this.newWorkPeriod(
          "",
          st,
          et,
          )
        )
      }
    }
  }

  removeWorkPeriod(){
    this.didSave = true;
    if(this.selectedDate){
      this.selectedWorkDay.workPeriods.pop();
    }
  }

  dateSelected(){
    return ((this.selectedDate? true:false) && this.edit);
  }

  disableSave(){
    let verifyTimes = true;
    let prev : Time = {"hour": 0, "minute": 0};


    for(let wp of this.selectedWorkDay.workPeriods){
      // Ensure end after start
      if(wp.start.hour > wp.end.hour){
         console.log(1);
        verifyTimes = false;
      } else if(wp.start.hour == prev.hour && wp.start.minute <= prev.minute) {
         console.log(2);
        verifyTimes = false;
      }

      // Ensure next wp start is after current start
      if(wp.start.hour < prev.hour){
        console.log(3);
        verifyTimes = false;
      } else if(wp.start.hour == wp.end.hour && wp.start.minute >= wp.end.minute) {
        console.log(4);
        verifyTimes = false;
      }
      prev = wp.end;
    }
    return !(this.dateSelected() && verifyTimes && this.edit);
  }

  newTime(hour, minute) : Time{
    return {
      "hour": hour,
      "minute": minute
    }
  }

  newWorkPeriod(description: string, start: Time, end: Time) : WorkPeriod{
    return {
      description,
      start,
      end
    }
  }

  newWorkDay(isWorking: boolean, date: string, notes: string, workPeriods: WorkPeriod[]) : WorkDay{
    return {
      isWorking,
      date,
      notes,
      workPeriods
    }
  }

  getuserList(){
    this.userCollection = this.firestore.collection<User>('Users');
    this.userList$ = this.userCollection.valueChanges();
    this.userList$.forEach(u => {
      this.userList = u;
      // this.dev_login();
    });
  }

  showAdminPage(){
    if(this.selectedUser.isAdmin){
      this.showAdmin = !this.showAdmin;
    }
  }

  showCalendarPage(){
    this.showCalendar = !this.showCalendar;
  }

  isKiosk(){

    return (this.selectedUser.position != 'kiosque')

  }
  
  showAdminIcon(){
    if(this.selectedUser){
      if(this.selectedUser.isAdmin){
        return true;
      }
    }
    return false;
  }

  login(){
    if(this.selectedName && this.pin && this.userList.length > 0){
      let i = this.userList.findIndex(i => i.name == this.selectedName);
      if(this.userList[i].pin == this.pin) {
        this.selectedUser = this.userList[i];
        this.verified = true;
        console.log("V1.0.0-2024-06-27");
        return;
      }
    }
  
  }
  logout(){
    this.verified = false;
  }

  dev_login(){
    let i = this.userList.findIndex(i => i.name == "Hubert Lachaine");
    this.selectedUser = this.userList[i];
    this.verified = true;
    this.showAdmin = true;
  }

  options: string[] = [
    "1-Arandanos azules - arrancar malas hierbas",
'2-Arandanos azules - cortar arandanos',
'3-Arandanos azules - cortar tallos',
'4-Arandanos azules - fertilizacion',
'5-Arandanos azules - Otro trabajo',
'6-Aubergine (berenjena) - Arrancar malas hierbas',
'7-Aubergine (berenjena) - Bomba',
'8-Aubergine (berenjena) - Cortar berenjena',
'9-Aubergine (berenjena) - Otro trabajo',
'10-Aubergine (berenjena) - Plantar',
'11-Calabazas  - Cortar calabazas ',
'12-Calabazas - Arrancar malas hierbas',
'13-Concombre (Pepino) - Arrancar malas hierbas',
'14-Concombre (Pepino) - Bomba',
'15-Concombre (Pepino) - Cortar pepino',
'16-Concombre (Pepino) - Otro trabajo',
'17-Concombre (Pepino) - Siembrar o plantar',
'18-Frambuesa otono - arrancar malas hierbas',
'19-Frambuesa otono - cortar frambuesa otono',
'20-Frambuesa otono - cortar tallos o fuego',
'21-Frambuesa otono - plantación',
'22-Frambuesa verano - arrancar malas hierbas',
'23-Frambuesa verano - bomba',
'24-Frambuesa verano - colocar tallos',
'25-Frambuesa verano - cortar frambuesa',
'26-Frambuesa verano - fertilizacion',
'27-Frambuesa verano - otro trabajo',
'28-Frambuesa verano - plantación',
'29-Fresa de otono - arrancar malas hierbas',
'30-Fresa de otono - arrancar stolons',
'31-Fresa de otono - bomba',
'32-Fresa de otono - cortar fresas',
'33-Fresa de otono - otro trabajo',
'34-Fresa de otono - plantación',
'35-Fresa de verano - plantación',
'36-Fresa verano - arrancar malas hierbas',
'37-Fresa verano - cortar fresas',
'38-Fresa verano - cortar las flores',
'39-Fresa verano - otro trabajo',
'40-Fresa verano - poner pajita',
'41-Fresa verano - poner stolons',
'42-Haricot (Judias y guisante) - Arrancar malas hierbas',
'43-Haricot (Judias y guisante) - Cortar judias',
'44-Irrigacion',
'45-Maiz - Cortar maiz',
'46-Maiz - Otro trabajo',
'47-Manzana - Arrancar malas hierbas',
'48-Manzana - Bomba',
'49-Manzana - Cortar manzanas',
'50-Manzana - Cortar tallos',
'51-Manzana - Fertilizacion',
'52-Manzana - Plantacion',
'53-Manzana- Otro trabajo',
'54-Manzana- Poste o atar plantas',
'55-Melon - Arrancar malas hierbas',
'56-Melon - Bomba',
'57-Melon - Cortar melon',
'58-Melon - Otro trabajo',
'59-Melon - Siembrar o plantar',
'60-Mercado - Cosina',
'61-Mercado - Jugo de frutas',
'62-Mercado - Kombucha',
'63-Mercado - Limpiar',
'64-Mercado - Otro trabajo',
'65-Mercado - Preparar caja de frutas o legumbre',
'66-Mercado - Transportar legumbre y frutas',
'67-Pimiento - Arrancar malas hierbas',
'68-Pimiento - Bomba',
'69-Pimiento - Cortar pimiento',
'70-Pimiento - Otro trabajo',
'71-Pimiento - Siembrar o plantar',
'72-Tomate - Arrancar malas hierbas',
'73-Tomate - Bomba',
'74-Tomate - Cortar tomate',
'75-Tomate - Instalacion cuerdas',
'76-Tomate - Otro trabajo',
'77-Tomate - Plantar',
'78-Zucchini (Calabacin)  - Arrancar malas hierbas',
'79-Zucchini (Calabacin)  - Cortar calabacin',
'80-Zucchini (Calabacin) - Siembrar ',
'81-Otro trabajo',
'82-Otro trabajo - Cortar ramas o arboles',
'83-Otro trabajo - Cuidar los animales',
'84-Otro trabajo - Fertilization',
'85-Otro trabajo - Pulverisation',
'86-Aparagus - Cortar',
'87-Asparagus - Fertilization',
'88-Asparagus - Otro trabajo'];

}

