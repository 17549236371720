import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CalendarMode } from 'ionic2-calendar/calendar';
import { CalendarComponent } from 'ionic2-calendar';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Time, User, WorkDay, WorkPeriod } from 'src/interfaces';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-calendar',
  templateUrl: './cal.component.html',
  styleUrls: ['./cal.component.css']
})
export class CalComponent implements OnInit {
  private userCollection: AngularFirestoreCollection<User>;
  userList$: Observable<User[]>;
  userList: User[] = [];

  @Input() selectedUser: User;

  viewTitle = "Calendar";

  calendar = {
    mode: 'month' as CalendarMode,
    currentDate: new Date(),
    startingDayWeek: 7
  };
  eventSource = [];
  workdays: WorkDay[];

  currentDate = new Date();
  searchDate:string;
  beginningOfYear: string;

  @ViewChild(CalendarComponent) myCal: CalendarComponent;

  constructor(
    private firestore: AngularFirestore,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.searchDate = this.currentDate.getFullYear()+"."+("0" + (this.currentDate.getMonth() + 1)).slice(-2)+"."+("0" + this.currentDate.getDate()).slice(-2);
    this.beginningOfYear = this.currentDate.getFullYear()+".01.01";
    this.getuserList();
    console.log(this.selectedUser);
    this.getWorkDays();
  }
  getuserList(){
    this.userCollection = this.firestore.collection<User>('Users');
    this.userList$ = this.userCollection.valueChanges();
    this.userList$.forEach(u => {
      this.userList = u;
      // this.dev_login();
    });
  }
  getWorkDays(){

    this.workdays = [];
    let ref = 'Users/'+this.selectedUser.id+'/Days/';
    console.log(this.selectedUser.id)
    console.log(this.searchDate);
    let workDayCollection = this.firestore.collection<WorkDay>(ref, r => r.where('date', ">=", this.beginningOfYear).orderBy('date', 'asc')).valueChanges();
    workDayCollection.forEach(d => {
      // console.log(d);
      for(let days of d){
        let temp = this.setWorkDay(days);
        // console.log(temp);
        if (temp.isWorking){
          for(const [index, wp] of temp.workPeriods.entries()){

            console.log(wp);

            let tempHour = wp.start.hour;
            let tempMinute = wp.start.minute;
            let tempEndHour = wp.end.hour;
            let tempEndMinute = wp.end.minute;
  
            // let tempStart = new Date(temp.date);
            // let tempEnd = new Date(temp.date);
            let tempStart = new Date();
            let tempEnd = new Date();

            console.log(temp.date);
            let num:any = temp.date.split('.');
            console.log(num);
            
            tempStart.setFullYear(num[0]);
            tempStart.setUTCMonth(num[1]);
            tempStart.setDate(num[2]);
            tempEnd.setFullYear(num[0]);
            tempEnd.setUTCMonth(num[1]);
            tempEnd.setDate(num[2]);

            tempStart.setMonth(tempStart.getMonth()-1);
            tempEnd.setMonth(tempEnd.getMonth()-1);
            console.log(tempStart);

            tempStart.setHours(tempHour);
            tempStart.setMinutes(tempMinute);
            tempEnd.setHours(tempEndHour);
            tempEnd.setMinutes(tempEndMinute);
  
            // console.log(tempStart);
            // console.log(tempEnd);
            var event:any = {
              startTime: tempStart,
              endTime: tempEnd,
              date: temp.date,
              title: "Période " + (index + 1),
              allDay: false
            }
            this.eventSource.push(event);
            
          }
        }
      }
      this.myCal.loadEvents();
      
    });
  }
  setWorkDay(wd): WorkDay{
    let temp : WorkPeriod[] = [];
    for(let wp of JSON.parse(wd.workPeriod)){
      temp.push(this.newWorkPeriod(wp.description, wp.start, wp.end));
    }
    return {
      "isWorking": wd.isWorking,
      "date": wd.date,
      "notes": wd.notes,
      "workPeriods": temp
    }
  }
  newWorkPeriod(description: string, start: Time, end: Time) : WorkPeriod{
    return {
      description,
      start,
      end
    }
  }

  getThisMonthView(){

  }

  onEventSelected(event){
    return event;
  }
  next() {
    this.myCal.slideNext();
  }

  back() {
    this.myCal.slidePrev();
  }
  onViewTitleChanged(title){
    this.viewTitle = title;
  }


}
