import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HoursComponent } from './hours/hours.component';
import { AdminComponent } from './admin/admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule,} from '@angular/material/button';
import {MatDatepickerModule,} from '@angular/material/datepicker';
import {MatFormFieldModule,} from '@angular/material/form-field';
import {MatGridListModule,} from '@angular/material/grid-list';
import {MatInputModule,} from '@angular/material/input';
import {MatListModule,} from '@angular/material/list';
import {MatSelectModule,} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import { IonicModule } from '@ionic/angular';
import { NgCalendarModule } from 'ionic2-calendar';
import { CalComponent } from './calendar/cal.component';


const mat = [
  MatCardModule,
  MatButtonModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatToolbarModule,
  MatIconModule,
  MatCheckboxModule,
  MatNativeDateModule
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HoursComponent,
    AdminComponent,
    CalComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    AppRoutingModule,
    mat,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    MatAutocompleteModule,
    IonicModule.forRoot(),
    NgCalendarModule
  ],
  providers: [MatDatepickerModule, MatNativeDateModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
