
<mat-list>
    <mat-list-item>
        <h1 style="margin: 25px">Admin Page</h1>
    </mat-list-item>
    <mat-list-item style="margin-top: 25px">
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [max]="maxDate" [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
    </mat-list-item>
    <mat-list-item style="margin-top: 50px">
        <h2>Filter by position</h2>
    </mat-list-item>
    <mat-list-item>
    <mat-checkbox [(ngModel)]="champsAFCFilter">
            Champs AFC
        </mat-checkbox>
    </mat-list-item>
    <mat-list-item>
        <mat-checkbox [(ngModel)]="champsPEFilter">
                Champs PE
            </mat-checkbox>
        </mat-list-item>
    <mat-list-item>
        <mat-checkbox [(ngModel)]="kioskFilter">
            Kiosque
        </mat-checkbox>
    </mat-list-item>
    <mat-list-item>
        <mat-checkbox [(ngModel)]="autresFilter">
                Autres
            </mat-checkbox>
        </mat-list-item>
    <mat-list-item>
        <mat-checkbox [(ngModel)]="adminFilter">
                Admin
            </mat-checkbox>
    </mat-list-item>
    <mat-list-item>
        <mat-checkbox [(ngModel)]="inactifFilter">
                Inactif
            </mat-checkbox>
    </mat-list-item>
    
    <mat-list-item>
        <h2>Export :</h2>
    </mat-list-item>
    <mat-list-items *ngFor="let w of getFilteredUsers()" style="margin-left: 25px;"> 
        <mat-checkbox disabled [checked]="true">{{w.name}}</mat-checkbox>
    </mat-list-items>
</mat-list>
<tr>
    <mat-error *ngIf="errorFlag">
        {{errorMessage}}
    </mat-error>

    <button style="margin: 25px" mat-raised-button disabled="{{!rangeSelected()}}" (click)="exportExcel('admin')">
        <mat-icon>send</mat-icon>
    </button>

    <button style="margin: 25px" mat-raised-button disabled="{{!rangeSelected()}}" (click)="exportShortExcel('short')">
        <mat-icon>cloud_download</mat-icon>
    </button>
</tr>
<div>

</div>
<mat-checkbox class="example-margin" [(ngModel)]="newUser">Add new user?</mat-checkbox>

<section *ngIf="newUser">

<mat-form-field class="example-margin" appearance="fill">
  <mat-label>Enter first name</mat-label>
  <input matInput placeholder="Full Name" [(ngModel)]="fname" required>
</mat-form-field>
<mat-form-field class="example-margin" appearance="fill">
    <mat-label>Enter last name</mat-label>
    <input matInput placeholder="Full Name" [(ngModel)]="lname" required>
  </mat-form-field>

<mat-checkbox class="example-margin" [(ngModel)]="admin">Admin?</mat-checkbox>

<mat-form-field class="example-margin" appearance="fill">
  <mat-label>Position</mat-label>
  <mat-select [(value)]="position">
    <mat-option value="kiosque">Kiosque</mat-option>
    <mat-option value="champs-afc">Champs AFC</mat-option>
    <mat-option value="champs-pe">Champs PE</mat-option>
    <mat-option value="autres">Autres</mat-option>
    <mat-option value="admin">Admin</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="example-margin" appearance="fill">
    <mat-label>Enter the account PIN</mat-label>
    <input matInput placeholder="2022" [(ngModel)]="pin" required>
  </mat-form-field>

  <div></div>
  <button mat-raised-button class="example-margin" (click)="createUser()">Create</button>

</section>

<mat-checkbox class="example-margin" [(ngModel)]="updateUser">Update user?</mat-checkbox>

<section *ngIf="updateUser">
    <mat-form-field appearance="fill">
        <mat-label>Nom/Nombre</mat-label>
        <mat-select [(ngModel)]="selectedUser" name="selectedUser">
            <mat-option value="{{i}}" *ngFor="let n of userList$ | async; index as i">
                {{n.name}}
                </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-margin" appearance="fill">
    <mat-label>Position</mat-label>
    <mat-select [(value)]="newPosition">
        <mat-option value="kiosque">Kiosque</mat-option>
        <mat-option value="champs-afc">Champs AFC</mat-option>
        <mat-option value="champs-pe">Champs PE</mat-option>
        <mat-option value="autres">Autres</mat-option>
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="inactif">Inactif</mat-option>
    </mat-select>
    </mat-form-field>


  <div></div>
  <button mat-raised-button class="example-margin" (click)="updateUserDoc()">Update</button>

</section>

